<template>
  <div id="device-configuration">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2"
          >Device <em>{{ device.device_id }}</em></span
        >
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <v-row v-if="!loading">
      <v-col cols="12" md="5" lg="4">
        <device-info-panel />
      </v-col>
      <v-col cols="12" md="7" lg="8">
        <device-overview ref="deviceOverview" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { getCurrentInstance } from "@vue/composition-api/dist/vue-composition-api"
import DeviceInfoPanel from "./components/DeviceInfoPanel.vue"
import DeviceOverview from "./components/DeviceOverview.vue"

import store from "@/store"

export default {
  name: "DeviceConfiguration",

  metaInfo() {
    return {
      title: `Edit Device`,
    }
  },

  components: {
    DeviceInfoPanel,
    DeviceOverview,
  },

  computed: {
    ...mapState({
      device: state => state.selected_device,
    }),
  },

  created() {
    store.dispatch("getDevice", this.deviceId).then(() => {
      this.loading = false
    })
  },

  setup() {
    const curr = getCurrentInstance()
    const deviceId = curr.proxy.$route.params.id

    if (!deviceId) {
      curr.proxy.$router.push({
        name: "next-stop-devices",
      })
    }

    return {
      deviceId,
      loading: true,
    }
  },
}
</script>

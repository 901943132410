<template>
  <div id="device-overview">
    <v-tabs v-model="currentTab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>
          {{ tab.title }}
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab">
      <v-tab-item v-if="canNotes">
        <v-card>
          <!-- Notes Filter fields start -->
          <v-row class="pt-4 pb-0 ma-0">
            <v-col cols="12" sm="4">
              <v-select
                v-model="sqPriority"
                :items="priorityItems"
                item-text="text"
                item-value="value"
                label="Priority"
                placeholder="Priority"
                outlined
                hide-details
                clearable
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqPriority')"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="sqClaimant"
                label="Claimant"
                outlined
                hide-details
                clearable
                persistent-clear
                dense
                class="user-search me-3"
                @change="val => addToSearchHistory(val, 'sqClaimant')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-menu
                v-model="menu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="sqCreated"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    outlined
                    hide-details
                    dense
                    clearable
                    class="user-search me-3"
                    v-on="on"
                    @change="val => addToSearchHistory(val, 'sqCreated')"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="sqCreated" no-title @input="menu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Notes Filter fields end -->
          <!-- Notes Table start -->
          <v-data-table
            :headers="noteHeaders"
            :items="filteredNotes"
            :sort-by.sync="noteSortBy"
            :sort-desc.sync="noteSortDesc"
            :custom-sort="customSort"
            class="font-weight-semibold text--primary"
            :must-sort="true"
          >
            <template v-slot:item.note_id="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.note_id }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.title="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.title }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.priority="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.priority }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.status="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.status }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.claimant="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.claimant }}</span>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-slot:item.convertCreatedDate="{ item }">
              <router-link
                :to="{ name: 'next-stop-note-config', params: { id: item.note_id } }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary" v-html="convertCreatedDate(item)"></span>
                  </div>
                </div>
              </router-link>
            </template>
          </v-data-table>
          <!-- Notes Table end -->
          <!-- Add Note Button, Modal and Form start -->
          <v-row class="pt-8 pl-5 pb-6 ma-0">
            <v-btn color="primary" elevation="0" @click="openDialog">Add Note</v-btn>
          </v-row>
          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="d-flex">
                <span class="headline flex-grow-1">Add Note</span>
                <v-btn color="secondary" outlined elevation="0" @click="closeDialog">Close</v-btn>
              </v-card-title>
              <v-card-text>
                <v-form lazy-validation @submit.prevent="() => {}">
                  <v-text-field v-model="formData.title" label="Title *"></v-text-field>
                  <v-textarea v-model="formData.comments" label="Comments *"></v-textarea>
                  <v-select v-model="formData.selectedPriority" :items="priorityOptions" label="Select Priority *">
                  </v-select>
                  <v-select v-model="formData.selectedStatus" :items="statusOptions" label="Select Status *"></v-select>
                  <v-text-field v-model="formData.claimant" label="Claimant *"></v-text-field>
                  <v-text-field v-model="formData.assigned" label="Assigned To (optional)"></v-text-field>
                  <v-text-field v-model="formData.location" label="Location (optional)"></v-text-field>
                  <v-btn class="mt-7" color="primary" :disabled="note_cannotSave" @click="addNote()"> Add </v-btn>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- Add Note Button, Modal and Form end -->
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-row class="mt-1 mb-1" style="align-items: center">
              <v-col cols="5">
                <v-text-field
                  id="startDateTime"
                  v-model="startDate"
                  label="Start datetime"
                  outlined
                  hide-details
                  dense
                  type="date"
                />
              </v-col>
              <v-col cols="5">
                <v-text-field
                  id="endDateTime"
                  v-model="endDate"
                  label="End datetime"
                  outlined
                  hide-details
                  dense
                  type="date"
                />
              </v-col>
              <v-col cols="2">
                <v-btn color="primary" style="min-width: 100%" @click="fetchNetworkData">Go</v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="[
                    {
                      text: 'Date',
                      value: 'date',
                      sortable: true,
                    },
                    {
                      text: 'Upload',
                      value: 'upload',
                      sortable: true,
                    },
                    {
                      text: 'Download',
                      value: 'download',
                      sortable: true,
                    },
                    {
                      text: 'Total Usage',
                      value: 'total_usage',
                      sortable: true,
                    },
                  ]"
                  :items="networkUsageData"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.date }}</td>
                      <td>{{ item.upload }}</td>
                      <td>{{ item.download }}</td>
                      <td>{{ item.total_usage }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mdiSpeedometerSlow, mdiTextBoxMultipleOutline } from "@mdi/js"
import { mapState } from "vuex"
import byteSize from "byte-size"
import axios from "axios"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import store from "@/store"
import urlHelpers from "@/mixins/urlHelpers"
import globalHelpers from "@/mixins/globalHelpers"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "DeviceOverview",
  mixins: [urlHelpers, globalHelpers],
  data() {
    return {
      currentTab: "",
      provisioning: [],
      dialog: false,
      is_loading: true,
      sqCreated: "",
      sqClaimant: "",
      sqPriority: null,
      noteSortBy: "note_id",
      networkUsage: [],
      networkUsageRequest: axios.CancelToken.source(),
      menu: false,
      // Notes Table Headers
      noteHeaders: [
        { text: "Note ID", value: "note_id" },
        { text: "Title", value: "title" },
        { text: "Priority", value: "priority" },
        { text: "Status", value: "status" },
        { text: "Claimant", value: "claimant" },
        { text: "Created", value: "convertCreatedDate" },
      ],
      priorityItems: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
      ],
      formData: {
        selectedType: "Device",
        selectedForeignId: null,
        title: "",
        comments: "",
        selectedPriority: null,
        selectedStatus: null,
        claimant: "",
        assigned: "",
        location: "",
      },
      startDate: dayjs.tz(new Date(), "Pacific/Auckland").subtract(1, "week").format("YYYY-MM-DD"),
      endDate: dayjs.tz(new Date(), "Pacific/Auckland").subtract(1, "day").format("YYYY-MM-DD"),
      noteSortDesc: false, // true for descending order, false for ascending order
    }
  },
  computed: {
    ...mapState({
      device: state => state.selected_device,
      notes: state => state.gtfs.notes,
    }),
    tabs() {
      const tabs = []
      if (this.canNotes) {
        tabs.push({ title: "Notes", icon: mdiTextBoxMultipleOutline })
      }

      tabs.push({ title: "Bandwidth", icon: mdiSpeedometerSlow })

      return tabs
    },
    canNotes() {
      return this.$auth.hasScope("notes")
    },
    // Notes Filtering
    filteredNotes() {
      return this.notes.filter(note => {
        let include = true
        if (include && this.sqCreated !== "" && this.sqCreated !== null) {
          // eslint-disable-next-line no-undef
          const date = dayjs(this.formatDate(note.created, "en-NZ"), "D/MM/YYYY, h:mm:ss a")
          include = date.format("YYYY-MM-DD") === this.sqCreated
        }
        if (include && this.sqClaimant !== "" && this.sqClaimant !== null) {
          include = note.claimant.toLowerCase().includes(this.sqClaimant.toLowerCase())
        }
        if (include && this.sqPriority !== "" && this.sqPriority !== null) {
          include = note.priority === this.sqPriority
        }

        return include
      })
    },
    convertCreatedDate() {
      return function (item) {
        return item.created ? this.formatDate(item.created, "en-NZ") : "Unknown"
      }
    },
    priorityOptions() {
      return ["Low", "Medium", "High", "Urgent"]
    },
    statusOptions() {
      return ["Open", "Pending", "On Hold", "Closed"]
    },
    note_cannotSave() {
      return (
        !this.formData.title ||
        !this.formData.comments ||
        !this.formData.selectedPriority ||
        !this.formData.selectedStatus ||
        !this.formData.claimant
      )
    },
    networkUsageData() {
      if (!this.networkUsage.length) {
        return []
      }

      return this.networkUsage.map(item => ({
        device_id: item.device_id,
        vehicle_id: item.vehicle_id,
        upload: byteSize(item.upload).toString(),
        upload_bytes: item.upload,
        download: byteSize(item.download).toString(),
        download_bytes: item.download,
        total_usage: byteSize(item.upload + item.download).toString(),
        total_bytes: item.upload + item.download,
        date: dayjs.tz(item.date, "Pacific/Auckland").format("YYYY-MM-DD"),
      }))
    },
  },
  created() {
    this.device_settings = JSON.parse(JSON.stringify(this.device))

    this.is_loading = true
    store
      .dispatch("getNotes", {
        type: "Device",
        foreign_id: this.device.device_id,
      })
      .then(() => {
        this.is_loading = false
      })
    this.formData.selectedForeignId = {
      title: this.getDeviceAndVehicleTitle(),
      value: this.device.device_id,
    }
  },
  methods: {
    async fetchNetworkData() {
      this.loading = true

      const startDate = dayjs.tz(this.startDate, "Pacific/Auckland").format("YYYY-MM-DD 00:00:00")
      const endDate = dayjs.tz(this.endDate, "Pacific/Auckland").format("YYYY-MM-DD 23:59:59")

      try {
        const [networkUsage] = await Promise.all([this.fetchNetworkUsage(startDate, endDate, this.device.device_id)])

        this.networkUsage = networkUsage
      } catch (error) {
        console.error(error)
      }

      this.loading = false
    },
    fetchNetworkUsage(startDate, endDate, deviceId) {
      this.networkUsageRequest.cancel("New fetch requested")
      this.networkUsageRequest = axios.CancelToken.source()

      return store.dispatch("getNetworkUsageByDevice", {
        start_datetime: startDate,
        end_datetime: endDate,
        device_id: deviceId,
        cancelToken: this.networkUsageRequest.token,
      })
    },

    /**
     * Overrides default sorting by table headers.
     */
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = null
        let sortB = null

        // Handle sorting based on clicked header column
        switch (sortByField) {
          case "convertCreatedDate": {
            // We don't want to use convertCreatedDate as it can return "Unknown"
            sortA = new Date(a?.created)
            sortB = new Date(b?.created)

            break
          }
          case "priority": {
            // Order by priority not alphabetically
            const priorityOrder = {
              urgent: 1,
              high: 2,
              medium: 3,
              low: 4,
            }

            sortA = priorityOrder[a.priority?.toLowerCase()] ?? 5
            sortB = priorityOrder[b.priority?.toLowerCase()] ?? 5

            break
          }
          default: {
            // Default sorting
            sortA = a[sortBy]
            sortB = b[sortBy]

            break
          }
        }

        if (sortA < sortB) {
          return -1
        }

        if (sortA > sortB) {
          return 1
        }

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    async addNote() {
      try {
        const { selectedForeignId, title, comments, selectedPriority, selectedStatus, claimant } = this.formData
        // if fields filled out
        if (
          title.trim() !== "" &&
          comments.trim() !== "" &&
          selectedPriority &&
          selectedStatus !== null &&
          claimant.trim() !== ""
        ) {
          await this.$store.dispatch("addNote", { formData: this.formData })
          store.commit("SET_SNACKBAR", {
            text: `The note ${title} on ${selectedForeignId.title} has been added`,
            color: "success",
          })
          // Reset the form fields after successful submission
          this.formData = {
            selectedType: "Device",
            selectedForeignId: {
              title: this.getDeviceAndVehicleTitle(),
              value: this.device.device_id,
            },
            title: "",
            comments: "",
            selectedPriority: null,
            selectedStatus: null,
            claimant: "",
            assigned: "",
            location: "",
          }
          this.dialog = false
        }
      } catch (error) {
        console.error("error:", error)
        store.commit("SET_SNACKBAR", {
          text: `The note ${this.formData.title} on ${this.formData.selectedForeignId.title} could not be added`,
          color: "error",
        })
      }
    },
    getDeviceAndVehicleTitle() {
      const deviceId = this.device.device_id
      const vehicleId = this.device.config.vehicle_id ? this.device.config.vehicle_id : null

      if (deviceId) {
        return vehicleId ? `Vehicle ${vehicleId} - Device ID: ${deviceId}` : `Device ID: ${deviceId}`
      }

      return "Unknown"
    },
  },
}
</script>
